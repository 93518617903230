.dialog {
  position: relative;
  width: 640px;
  max-width: calc(100vw - 8px);
  height: 395px;
  max-height: 395px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--color--background-variant);
  background-color: var(--color--background-variant);
}

.header, .footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-preferred-size: var(--height--row);
  -ms-flex-preferred-size: var(--height--row);
      flex-basis: var(--height--row);
      flex-basis: var(--height--row);
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.header {
  background-color: var(--color--background-highlight);
  background-color: var(--color--background-highlight);
  font-variant-caps: all-small-caps;
  font-size: 16px;
  color: var(--color--text);
  color: var(--color--text);
  padding: 0 16px;
}

.contents {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: visible;
  --max-height: 295px;
  max-height: var(--max-height);
  max-height: var(--max-height)
}

.contents.noFooter {
    --max-height: calc(295px + var(--height--row));
    --max-height: calc(295px + var(--height--row));
  }

.footer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  padding: 0 9px;
}

.footerButton, .fakeButton {
  height: 34px;
  max-width: 100px;
}

.footerTitle {
  margin-left: auto;
  margin-right: 16px;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

.fakeButton {
  opacity: 0;
  pointer-events: none;
}

.closeButton {
  composes: outlinedIcon from '../common/styles.css';
  position: absolute;
  margin: 8px;
  left: var(--left);
  left: var(--left);
  right: var(--right);
  right: var(--right);
}

@media (min-width: 740px) {
  .closeButton {
    --left: 100%;
  }
}

@media (max-width: 739px) {
  .closeButton {
    --right: 0;
  }
}
