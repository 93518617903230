.emptyState {
  opacity: 0.5;
}

.placeholder {
  composes: placeholder from '../../common/styles.css';
}

.loadMore {
  composes: placeholder from '../../common/styles.css';
  height: var(--height--row);
  height: var(--height--row);
}

.filterInput {
  width: calc(100% - 32px);
  margin: 0 16px;
}
