.footer {
  height: calc(var(--height--row) * 3);
  height: calc(var(--height--row) * 3);
  background-color: var(--color--background-highlight);
  background-color: var(--color--background-highlight);
}

.title {
  font-variant-caps: all-small-caps;
  margin-left: var(--width--indicator);
  margin-left: var(--width--indicator);
}

.item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: var(--height--row);
  height: var(--height--row);
  width: 100%;
  padding: 0 16px;
}
