.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 16px;
  font-weight: var(--font-weight--semibold);
  font-weight: var(--font-weight--semibold);
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
