.toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index--toolbar);
  z-index: var(--z-index--toolbar);
  height: var(--height--toolbar);
  height: var(--height--toolbar);
  background-color: var(--color--background-toolbar);
  background-color: var(--color--background-toolbar);
}

.inner {
  width: var(--width);
  width: var(--width);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}

.logoContainer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: auto;
}

.pageIcon {
  font-size: 24px;
  padding: 8px;
}

.mobile {
  --display: flex;
  display: var(--display);
  display: var(--display);
}

.desktop {
  --display: flex;
  display: var(--display);
  display: var(--display);
  width: 280px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  cursor: pointer;
}

@media (min-width: 888px) {
  .inner { --width: 880px; }
  .mobile { --display: none; }
}

@media (max-width: 887px) {
  .logoContainer {
    position: relative;
    padding-left: 4px;
    height: 100%;
    background-color: var(--color--background-toolbar-variant);
    background-color: var(--color--background-toolbar-variant)
  }

    .logoContainer::after {
      position: absolute;
      left: 100%;
      content: '';
      width: 0;
      height: 0;
      border-width: 30px 0 30px 16px;
      border-style: solid;
      border-color: transparent;
      border-left-color: var(--color--background-toolbar-variant);
      border-left-color: var(--color--background-toolbar-variant);
    }

  .inner { --width: 100%; }
  .desktop { --display: none; }
  .hidden { display: none; }
}

.title {
  font-family: var(--font--body);
  font-family: var(--font--body);
  font-weight: var(--font-weight--bold);
  font-weight: var(--font-weight--bold);
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  margin-left: 8px;
  margin-right: auto;
  z-index: 1;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.action {
  margin: 0 16px 0 0;
}

.textField {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0 20px;
}
