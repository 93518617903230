.productTypeName, .productTypeColor, .productName, .productQuantity, .discontinueButton {
  margin: 0 10px 0 6px;
}

.productTypeColor {
  margin-left: 8px;
  width: 32px;
  height: 32px;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.productTypeName {
  -ms-flex-preferred-size: 280px;
      flex-basis: 280px;
}

.productSku, .productName, .productQuantity {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

.productName {
  -webkit-box-flex: 346;
      -ms-flex-positive: 346;
          flex-grow: 346;
}

.productQuantity {
  -webkit-box-flex: 60;
      -ms-flex-positive: 60;
          flex-grow: 60;
}

.productSku {
  -webkit-box-flex: 140;
      -ms-flex-positive: 140;
          flex-grow: 140;
}

.discontinueButton, .deleteButton {
  font-size: 16px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-bottom: 8px;
}

.deleteTypeButton, .sortButton {
  margin-left: auto;
  margin-right: 8px;
}

.deleteButton {
  margin-left: 8px;
  margin-right: 8px;
}

.newProductType {
  margin-left: 10px;
  padding: 0 16px;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

.placeholder {
  composes: placeholder from '../../common/styles.css';
  height: var(--height--row);
  height: var(--height--row);
}

.discontinued {
  opacity: 0.3;
}
