.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.score {
  font-size: 11px;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
  pointer-events: none;
}

.thumbUp, .thumbDown, .thumb {
  cursor: pointer;
  margin: 2px;
  font-size: 18px;
  padding: 0;
}

/* single use thumb */
.thumb {
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
  color: var(--color);
  color: var(--color);
  pointer-events: var(--pointer-events);
  pointer-events: var(--pointer-events)
}
.thumb.up {
    --color: var(--color--success);
    --color: var(--color--success);
    --pointer-events: none;
  }

/* bidirectional thumbs */
.thumbUp {
  --transform: translateX(1px) translateY(-2px);

  -webkit-transition: opacity 0.2s, color 0.2s, -webkit-transform 0.2s;

  transition: opacity 0.2s, color 0.2s, -webkit-transform 0.2s;

  -o-transition: opacity 0.2s, transform 0.2s, color 0.2s;

  transition: opacity 0.2s, transform 0.2s, color 0.2s;

  transition: opacity 0.2s, transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  -webkit-transform: var(--transform);
  -webkit-transform: var(--transform);
      -ms-transform: var(--transform);
      -ms-transform: var(--transform);
          transform: var(--transform);
          transform: var(--transform);
  color: var(--color);
  color: var(--color);
  opacity: var(--opacity);
  opacity: var(--opacity);
  z-index: var(--z-index);
  z-index: var(--z-index);
  pointer-events: var(--pointer-events);
  pointer-events: var(--pointer-events)
}
.thumbUp.up, .thumbUp.down {
    --transform: translateX(calc(-50% - 1px)) translateY(-2px);
  }
.thumbUp.down {
    --opacity: 0;
    --pointer-events: none;
  }
.thumbUp.up {
    --color: var(--color--success);
    --color: var(--color--success);
    --z-index: 1;
  }

.thumbDown {
  --transform: translateX(-1px) translateY(2px);

  -webkit-transition: opacity 0.2s, color 0.2s, -webkit-transform 0.2s;

  transition: opacity 0.2s, color 0.2s, -webkit-transform 0.2s;

  -o-transition: opacity 0.2s, transform 0.2s, color 0.2s;

  transition: opacity 0.2s, transform 0.2s, color 0.2s;

  transition: opacity 0.2s, transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  -webkit-transform: var(--transform);
  -webkit-transform: var(--transform);
      -ms-transform: var(--transform);
      -ms-transform: var(--transform);
          transform: var(--transform);
          transform: var(--transform);
  color: var(--color);
  color: var(--color);
  opacity: var(--opacity);
  opacity: var(--opacity);
  z-index: var(--z-index);
  z-index: var(--z-index);
  pointer-events: var(--pointer-events);
  pointer-events: var(--pointer-events)
}

.thumbDown.up, .thumbDown.down {
    --transform: translateX(calc(50% + 1px)) translateY(2px);
  }

.thumbDown.up {
    --opacity: 0;
    --pointer-events: none;
  }

.thumbDown.down {
    --color: var(--color--warn);
    --color: var(--color--warn);
    --z-index: 1;
  }
