.toast {
  position: fixed;
  top: 100%;
  left: 50%;
  padding: 16px 32px 16px 16px;
  background-color: var(--color--background-variant);
  background-color: var(--color--background-variant);
  -webkit-box-shadow: 0 4px 8px var(--color--shadow);
  -webkit-box-shadow: 0 4px 8px var(--color--shadow);
          box-shadow: 0 4px 8px var(--color--shadow);
          box-shadow: 0 4px 8px var(--color--shadow);
  font-size: 18px;
  border-left: var(--width--indicator) solid var(--color--brand);
  border-left: var(--width--indicator) solid var(--color--brand);
  cursor: pointer;
  -webkit-transform: var(--transform);
  -webkit-transform: var(--transform);
      -ms-transform: var(--transform);
      -ms-transform: var(--transform);
          transform: var(--transform);
          transform: var(--transform);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.3s;
  transition: opacity 0.1s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.1s;
  transition: transform 0.3s, opacity 0.1s;
  transition: transform 0.3s, opacity 0.1s, -webkit-transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0, 1.175);
       -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0, 1.175);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0, 1.175);
  z-index: var(--z-index--over-toolbar);
  z-index: var(--z-index--over-toolbar)
}

.toast:hover {
    opacity: 0.8;
  }

.visible {
  --transform: translateY(calc(-100% - 64px)) translateX(-50%);
}

.hidden {
  --transform: translateY(50px) translateX(-50%);
}
