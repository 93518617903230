.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index--toolbar);
  z-index: var(--z-index--toolbar);
  height: var(--height--toolbar);
  height: var(--height--toolbar);
  background-color: var(--color--background-toolbar);
  background-color: var(--color--background-toolbar);
}

.inner {
  width: var(--width);
  width: var(--width);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}

@media (min-width: 888px) {
  .inner { --width: 880px; }
}

@media (max-width: 887px) {
  .inner { --width: 100%; }
}
