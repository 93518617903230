.detailsButton {
  composes: detailsButton from '../conventions/card.css';
}

.placeholder {
  composes: placeholder from '../../common/styles.css';
}

.footer {
  padding: 0 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.button {
  height: 32px;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0 8px;
}
