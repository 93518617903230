.header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: var(--width--card);
  width: var(--width--card);
  height: 34px;
  margin: 16px;
  padding-left: 16px;
  font-variant-caps: all-small-caps;
  font-size: 16px;
  font-weight: var(--font-weight--semibold);
  font-weight: var(--font-weight--semibold);
}

.emptyState {
  opacity: 0.5;
}

.placeholder, .placeholderLarge {
  composes: placeholder from '../../common/styles.css';
}

.placeholder {
  height: var(--height--row);
  height: var(--height--row);
}
