.placeholder {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
  padding: 0 40px;
  color: var(--color--text-placeholder);
}

.outlinedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 0;
  font-size: 20px;
  border-radius: 100%;
  border: 2px solid var(--color--divider);
}

.emptyState {
  opacity: 0.5;
}
