.container {
  margin-left: auto;
  -ms-flex-preferred-size: 280px;
      flex-basis: 280px;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media (max-width: 887px) {
  .container {
    position: fixed;
    top: 0;
    right: 100%;
    width: 100vw;
    z-index: var(--z-index--over-toolbar);
    z-index: var(--z-index--over-toolbar);
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    -o-transition: transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    pointer-events: none;
  }

  .focused {
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%);
    pointer-events: auto;
  }

  .nav {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (min-width: 888px) {
  .container {
    padding-top: 16px;
  }

  .nav {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: fixed;
    width: 280px;
    max-height: calc(100% - 32px - var(--height--toolbar));
    max-height: calc(100% - 32px - var(--height--toolbar));
    overflow-y: auto;
  }
}
