.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 100%;
}

.primary {
  color: var(--color--brand);
  color: var(--color, var(--color--brand))
}

.primary:hover, .primary:focus {
    --color: var(--color--brand-variant);
    --color: var(--color--brand-variant);
  }

.secondary {
  color: var(--color--text);
  color: var(--color--text);
  background-color: var(--background-color);
  background-color: var(--background-color)
}

.secondary:not(.quiet):hover {
    --background-color: var(--color--background-hover-highlight);
    --background-color: var(--color--background-hover-highlight);
  }

.tertiary {
  color: var(--color--divider);
  color: var(--color--divider);
}
