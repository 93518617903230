.button {
  height: 44px;
  border: none;
  border-radius: 2px;
  font-family: var(--font--body);
  font-family: var(--font--body);
  font-weight: var(--font-weight--semibold);
  font-weight: var(--font-weight--semibold);
  font-variant-caps: small-caps;
  text-transform: lowercase;
  padding: 0 16px
}

.button:not(:disabled) {
    cursor: pointer;
    -webkit-transition: 0.1s background-color;
    -o-transition: 0.1s background-color;
    transition: 0.1s background-color;
  }

.button:disabled {
    opacity: 0.5;
  }

@media (min-width: 480px) {
  .button {
    min-width: 152px;
    padding: 0;
  }
}

.primary, .secondary, .danger {
  color: var(--color--text);
  color: var(--color--text);
  font-size: 16px;
  -webkit-box-shadow: 0 4px 4px 0px var(--color--shadow);
  -webkit-box-shadow: 0 4px 4px 0px var(--color--shadow);
          box-shadow: 0 4px 4px 0px var(--color--shadow);
          box-shadow: 0 4px 4px 0px var(--color--shadow);
}

.primary {
  background-color: var(--color--brand);
  background-color: var(--color--brand);
}

.danger {
  background-color: var(--color--warn);
  background-color: var(--color--warn);
}

.secondary {
  background-color: var(--color--background);
  background-color: var(--color--background);
}

.tertiary {
  background-color: transparent;
  color: var(--color--brand);
  color: var(--color--brand);
  font-size: 18px;
  white-space: nowrap
}

.tertiary:not(:disabled):hover {
    background-color: var(--color--background-hover-highlight);
    background-color: var(--color--background-hover-highlight);
  }
