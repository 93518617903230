.card {
  position: relative;
  overflow: visible;
  background-color: var(--color--background-variant);
  background-color: var(--color--background-variant);
  --margin: 16px 0;
  margin: var(--margin);
  margin: var(--margin);
  width: var(--width);
  width: var(--width);
  max-width: var(--max-width);
  max-width: var(--max-width);
}

@media (min-width: 440px) {
  .card {
    --margin: 16px;
    --width: calc(100vw - 68px);
  }
}

@media (min-width: 888px) {
  .card {
    --width: var(--width--card);
    --width: var(--width--card);
    --max-width: calc(100% - 68px);
  }
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: var(--height--row);
  height: var(--height--row);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: visible;
  background-color: var(--color--background-highlight);
  background-color: var(--color--background-highlight);
}

.content {
  margin: 0;
  padding: 0;
  overflow: visible;
}

.rightAction {
  margin: 0 12px 0 0;
  font-size: 24px;
  -ms-flex-item-align: center;
      align-self: center;
}

.topAction, .bottomAction {
  composes: outlinedIcon from '../../common/styles.css';
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;

  position: var(--position);

  position: var(--position);
  left: var(--left);
  left: var(--left);
  top: var(--top);
  top: var(--top);
  bottom: var(--bottom);
  bottom: var(--bottom);
  margin: var(--margin);
  margin: var(--margin);
}

@media (max-width: 439px) {
  .bottomAction {
    --margin: 8px 8px 8px auto;
  }
}

@media (min-width: 440px) {
  .topAction, .bottomAction {
    --position: absolute;
    --left: 100%;
    --margin: 8px;
  }

  .topAction {
    --top: 0;
  }

  .bottomAction {
    --bottom: 0;
  }
}
