.headerButton {
  height: 32px;
  margin-left: auto;
}

.caps {
  font-variant-caps: all-small-caps;
}

.value {
  text-align: left;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

.description {
  padding: 0 20px;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
  font-size: 10pt;
}

.nowrap {
  white-space: nowrap;
}

.verificationStatus {
  margin-right: 4px;
}
