.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: var(--height--row);
  height: var(--height--row);
  width: 100%;
}

.clickable {
  cursor: pointer;
  background-color: var(--background-color);
  background-color: var(--background-color)
}

.clickable:hover {
    --background-color: var(--color--hover-highlight);
    --background-color: var(--color--hover-highlight);
  }

.dragHandle {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: var(--height--row);
  width: var(--height--row);
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}
