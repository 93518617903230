.link, .link:link, .link:visited {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--color--brand);
  color: var(--color, var(--color--brand));
  text-decoration: none;
  cursor: pointer;
}

.link:hover, .link:active, .link:focus {
  --color: var(--color--brand-variant);
  --color: var(--color--brand-variant);
}
