.container {
  position: absolute;
  top: var(--height--toolbar);
  top: var(--height--toolbar);
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.boldFooter {
  margin: 0 16px;
  font-weight: var(--font-weight--bold);
  font-weight: var(--font-weight--bold);
}

.resendEmailButton {
  padding: 0 16px;
}

:global(.signedOut) {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
