.findMore {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0 16px;
  height: 50px;
}

.view, .findMore {
  font-variant-caps: all-small-caps;
  font-weight: var(--font-weight--semibold);
  font-weight: var(--font-weight--semibold);
}

.placeholder {
  composes: placeholder from '../../common/styles.css'
}
