.container {
  position: relative;
  display: inline-block;
}

.target {
  display: inline-block;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.tooltip {
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: var(--color--background-tooltip);
  background-color: var(--color--background-tooltip);
  opacity: 0;
  opacity: var(--opacity, 0);
  -webkit-transform: translateX(-50%) scale(0.8);
  -webkit-transform: var(--transform, translateX(-50%) scale(0.8));
      -ms-transform: translateX(-50%) scale(0.8);
      -ms-transform: var(--transform, translateX(-50%) scale(0.8));
          transform: translateX(-50%) scale(0.8);
          transform: var(--transform, translateX(-50%) scale(0.8));
  -webkit-transform-origin: center bottom;
      -ms-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  -o-transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  pointer-events: none;
  text-align: center;
  font-weight: var(--font-weight--regular);
  font-weight: var(--font-weight--regular);
  white-space: nowrap;
  z-index: var(--z-index--over-toolbar);
  z-index: var(--z-index--over-toolbar)
}

.tooltip::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px;
    border-color: transparent;
    border-top-color: var(--color--background-tooltip);
    border-top-color: var(--color--background-tooltip);
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }

.target:hover + .tooltip, .target.clickable:focus + .tooltip {
  --opacity: 1;
  --transform: translateX(-50%) scale(1);
}
