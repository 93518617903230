.productName, .priceQuantity, .pricePrice, .removeButton {
  margin: 0 10px 0 6px;
}

.productName, .priceQuantity, .pricePrice {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

.productName {
  -webkit-box-flex: 240;
      -ms-flex-positive: 240;
          flex-grow: 240;
}

.priceQuantity, .pricePrice {
  -webkit-box-flex: 115;
      -ms-flex-positive: 115;
          flex-grow: 115;
}

.removeButton {
  font-size: 16px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-bottom: 8px;
}

.placeholder {
  composes: placeholder from '../../common/styles.css'
}

.any {
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}
