.pager {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.dot {
  width: 10px;
  width: var(--size--dot, 10px);
  height: 10px;
  height: var(--size--dot, 10px);
  border-radius: 100%;
  margin: 4px;
  background-color: rgba(0, 0, 0, 0.2)
}

.dot.current {
    background-color: var(--color--brand);
    background-color: var(--color--brand);
  }

@media (min-width: 480px) {
  .dot {
    --size--dot: 18px;
  }
}
