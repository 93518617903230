.starContainer {
  display: inline-block;
  margin-left: -4px;
  margin-right: 10px;
}

.star {
  font-size: 16px;
}

.starSelected {
  color: var(--color--brand-variant);
  color: var(--color--brand-variant);
}

.rowTitle {
  font-variant-caps: all-small-caps;
}

.detailsButton {
  margin-left: auto;
}
