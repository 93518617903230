.info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.record {
  border-left: var(--width--indicator) solid var(--color--background-highlight);
  border-left: var(--width--indicator) solid var(--color--background-highlight);
}

.expense {
  border-left: var(--width--indicator) solid var(--color--brand);
  border-left: var(--width--indicator) solid var(--color--brand);
}

.time {
  font-size: 14px;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

.products, .title, .category {
  font-weight: var(--font-weight--semibold);
  font-weight: var(--font-weight--semibold);
  font-size: 16px;
}

.title {
  font-variant-caps: all-small-caps;
  margin-left: var(--width--indicator);
  margin-left: var(--width--indicator);
}

.item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 16px;
}

.amount {
  font-variant-numeric: tabular-nums;
  font-weight: var(--font-weight--bold);
  font-weight: var(--font-weight--bold);
}
