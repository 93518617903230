.container {
  position: relative;
  display: inline-block;
  height: 50px;
}

.input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  padding-right: 10px;
  padding-right: var(--padding-right, 10px);
  margin: 0;
  width: 100%;
  font-family: var(--font--body);
  font-family: var(--font--body);
  font-size: 16px;
  color: var(--color--text);
  color: var(--color--text);
  background-color: transparent;
  border: none
}

.input.withAction {
    --padding-right: 40px;
  }

.title, .placeholder {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  bottom: 10px;
  font-size: 16px;
  font-size: var(--font-size, 16px);
  padding-left: 10px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  -webkit-transform: translateY(0);
  -webkit-transform: var(--transform, translateY(0));
      -ms-transform: translateY(0);
      -ms-transform: var(--transform, translateY(0));
          transform: translateY(0);
          transform: var(--transform, translateY(0));
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
  -webkit-transition: 0.2s font-size, 0.2s -webkit-transform;
  transition: 0.2s font-size, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s font-size;
  transition: 0.2s transform, 0.2s font-size;
  transition: 0.2s transform, 0.2s font-size, 0.2s -webkit-transform;
}

.input:focus ~ .title, .input:not(.empty) ~ .title {
  --transform: translateY(-20px);
  --font-size: 12px;
}

.input:focus ~ .title {
  color: var(--color--brand);
  color: var(--color--brand);
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color--divider);
  background-color: var(--color--divider)
}

.underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-transform: scaleX(0);
    -webkit-transform: var(--transform, scaleX(0));
        -ms-transform: scaleX(0);
        -ms-transform: var(--transform, scaleX(0));
            transform: scaleX(0);
            transform: var(--transform, scaleX(0));
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    -o-transition: transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    background-color: var(--color--brand);
    background-color: var(--background-color, var(--color--brand));
  }

.input:focus ~ .underline::after {
  --transform: scaleX(1);
}

.error ~ .underline::after {
  --background-color: var(--color--warn);
  --background-color: var(--color--warn);
}

.error {
  display: inline-block;
  display: var(--display, inline-block);
  position: absolute;
  right: 8px;
  bottom: 6px;
  font-size: 14px;
}

.input:focus ~ .error {
  --display: none;
}

.errorIcon {
  color: var(--color--warn);
  color: var(--color--warn);
}

.actionButton {
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 8px;
}
