.item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.truncation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  margin-left: 16px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

.vote {
  -ms-flex-preferred-size: 40px;
      flex-basis: 40px;
  margin: 0 16px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.placeholder {
  composes: placeholder from '../../common/styles.css';
}

.shareInfoCard {
  margin-bottom: 0;
}

.disclaimerCard {
  margin-top: 0;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
  background-color: var(--color--background);
  background-color: var(--color--background);
}

.disclaimer {
  opacity: 0.5;
  padding: 16px;
}

.detailsButton, .sendButton {
  margin-left: auto;
}

.sendButton {
  font-size: 20px;
}

.form {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  padding: 6px;
  height: 150px;
}

.full {
  width: 100%;
  height: 100%;
}
