.info {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: var(--height--row);
  height: var(--height--row);
}

.note {
  border-radius: 4px;
  background: var(--color--background-overlay);
  background: var(--color--background-overlay);
  margin: 6px;
  padding: 10px;
}

.rule {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-left: 16px;
  height: 1px;
  background-color: var(--color--divider);
  background-color: var(--color--divider);
}

.type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between
}

.type:not(:first-child) {
    padding-top: 4px;
  }

.type:not(:last-child) {
    padding-bottom: 4px;
    border-bottom: 1px solid var(--color--divider);
    border-bottom: 1px solid var(--color--divider);
  }

.products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.product:not(:last-child) {
  margin-bottom: 8px;
}

.placeholder {
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

.editIcon {
  padding: 8px;
  margin-left: -8px;
}
