.card {
  position: absolute;
  overflow: visible;
  background-color: var(--color--background-variant);
  background-color: var(--color--background-variant);
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: var(--height--row);
  height: var(--height--row);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: visible;
  background-color: var(--color--background-highlight);
  background-color: var(--color--background-highlight);
}

.content {
  margin: 0;
  padding: 0;
  overflow: visible;
}

.rightAction {
  composes: rightAction from './card.css';
  font-size: 18px;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

@media (min-width: 1312px) {
  .card {
    max-width: calc(100vw / 2 - 440px - 16px);
    left: 600px;
    width: 250px;
  }
}

@media (min-width: 888px) and (max-width: 1311px) {
  .card {
    left: calc(600px - 250px + (100vw / 2 - 440px - 16px));
    width: 250px;
    -webkit-box-shadow: 0 0 8px 2px var(--color--shadow);
    -webkit-box-shadow: 0 0 8px 2px var(--color--shadow);
            box-shadow: 0 0 8px 2px var(--color--shadow);
            box-shadow: 0 0 8px 2px var(--color--shadow);
  }
}

@media (min-width: 440px) and (max-width: 887px) {
  .card {
    left: calc(100vw - 266px);
    width: 250px;
    -webkit-box-shadow: 0 0 8px 2px var(--color--shadow);
    -webkit-box-shadow: 0 0 8px 2px var(--color--shadow);
            box-shadow: 0 0 8px 2px var(--color--shadow);
            box-shadow: 0 0 8px 2px var(--color--shadow);
  }
}

@media (max-width: 439px) {
  .card {
    width: 100vw;
    left: 0;
    bottom: 0;
    top: unset !important;
    -webkit-box-shadow: 0 0 15px 4px var(--color--shadow);
    -webkit-box-shadow: 0 0 15px 4px var(--color--shadow);
            box-shadow: 0 0 15px 4px var(--color--shadow);
            box-shadow: 0 0 15px 4px var(--color--shadow);
  }
}
