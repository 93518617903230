.textarea {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--color--background-overlay);
  background-color: var(--color--background-overlay);
  color: var(--color--text);
  color: var(--color--text);
  font: var(--font--body);
  font: var(--font--body);
  border: none;
  border-radius: 2px;
  padding: 10px;
  resize: none;
}
