.title {
  display: inline;
  padding: 0;
  margin: 0;
  font-weight: var(--font-weight--bold);
  font-weight: var(--font-weight--bold);
  font-size: 18px;
}

.splashBanner {
  margin-top: 100px;
  padding: 20px;
  width: 60%;
  min-width: 335px;
}

.headline {
  font-size: 32pt;
}

.paragraph {
  font-size: 14pt;
}

.links {
  padding: 0 16px
}

.links a {
    margin-right: 16px;
  }

.copy {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 600px;
  font-size: 14pt;
  line-height: 1.3
}

.copy p, .copy ol li {
    text-align: justify;
  }

.copy p {
    text-indent: 20pt;
  }

.copy h1 { font-size: 32pt; }

.copy h2 { font-size: 20pt; }

.copy h1 {
    margin-left: 20pt;
  }

.copy a:link, .copy a:visited {
    color: var(--color--brand);
    color: var(--color--brand);
    text-decoration: none;
  }

.copy a:hover, .copy a:active {
    color: var(--color--brand-variant);
    color: var(--color--brand-variant);
    text-decoration: none;
  }

:global(.signedOut) .copy {
  margin: 0 auto;
  max-width: 680px
}

@media (max-width: 640px) {

:global(.signedOut) .copy {
    padding: 0 20px
}
  }
