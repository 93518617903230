.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}

.dragIndicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 2px;
  margin-right: 4px;
  background-color: var(--color--brand);
  background-color: var(--color--brand)
}

.dragIndicator::after {
    content: '';
    position: absolute;
    left: 100%;
    border-radius: 100%;
    border: 2px solid var(--color--brand);
    border: 2px solid var(--color--brand);
    width: 6px;
    height: 6px;
  }
