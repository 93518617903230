.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 887px) {
  .container {
    background: var(--color--background-toolbar);
    background: var(--color--background-toolbar);
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}

@media (min-width: 888px) {
  .container {
    width: 100%;
  }
}

.item {
  position: relative;
  width: 100%;
  height: var(--height);
  height: var(--height);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: var(--flex-direction);
      -ms-flex-direction: var(--flex-direction);
          flex-direction: var(--flex-direction);
          flex-direction: var(--flex-direction);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--color--text);
  color: var(--color--text);
  -webkit-transition: 0.1s background-color;
  -o-transition: 0.1s background-color;
  transition: 0.1s background-color;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.item:hover:not(.disabled) {
    cursor: pointer;
    background-color: var(--color--background-hover-highlight);
    background-color: var(--color--background-hover-highlight);
  }

@media (max-width: 887px) {
  .item {
    --height: var(--height--toolbar);
    --height: var(--height--toolbar);
    --flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .children {
    display: none;
  }
}

@media (min-width: 888px) {
  .item {
    --height: var(--height--row);
    --height: var(--height--row);
    --flex-direction: row;
  }
}

.disabled {
  opacity: 0.5;
}

.indent {
  display: inline-block;
}

.icon {
  font-size: 24px;
}

@media (max-width: 887px) {
  .title {
    margin-top: 2px;
    font-size: 10px;
    color: var(--color--text-placeholder);
    color: var(--color--text-placeholder);
  }
}

@media (min-width: 888px) {
  .title {
    margin-left: 10px;
  }
}

.indicator {
  display: inline-block;
  height: var(--height);
  height: var(--height);
  width: var(--width);
  width: var(--width);
}

@media (max-width: 887px) {
  .indicator {
    --height: var(--width--indicator);
    --height: var(--width--indicator);
    --width: 100%;
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 888px) {
  .indicator {
    --height: 100%;
    --width: var(--width--indicator);
    --width: var(--width--indicator);
    margin-right: 12px;
  }
}

.indicatorSelected {
  background-color: var(--color--brand);
  background-color: var(--color--brand);
}

.indicatorDefault {
  background-color: var(--color--background-variant);
  background-color: var(--color--background-variant);
}

.indicatorDeep {
  background-color: var(--color--brand-variant);
  background-color: var(--color--brand-variant);
}
