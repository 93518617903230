.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  pointer-events: none;
}

.fade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  -o-transition: transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s
}

.fade > * {
    pointer-events: auto;
  }

.visible {
  z-index: 1;
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
