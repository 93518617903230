.placeholder {
  composes: placeholder from '../../common/styles.css';
}

.emptyState {
  composes: emptyState from '../../common/styles.css';
}

.any {
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}
