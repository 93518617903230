.container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: visible;
  width: var(--width);
  width: var(--width);
}

.placeholder {
  composes: placeholder from '../common/styles.css';
}

.emptyState {
  opacity: 0.5;
}

@media (min-width: 888px) {
  .container {
    --width: calc(600px + ((100vw - 600px - 280px) / 2));
  }

  :global(.signedOut) .container {
    --width: 880px;
  }
}

@media (max-width: 887px) {
  .container {
    --width: 100%;
  }
}
