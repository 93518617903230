.title {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}

.cover {
  z-index: var(--z-index--over-toolbar);
  z-index: var(--z-index--over-toolbar);
}

.container {
  position: relative;
  display: inline-block;
  height: 50px;
  overflow: visible;
}

.selection {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  border-bottom: 1px solid var(--color--divider);
  border-bottom: 1px solid var(--color--divider);
  padding-top: 10px;
}

.selection, .option {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 10px;
}

.optionsContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  max-height: 0;
  -webkit-box-shadow: 0 0 8px var(--color--shadow);
  -webkit-box-shadow: 0 0 8px var(--color--shadow);
          box-shadow: 0 0 8px var(--color--shadow);
          box-shadow: 0 0 8px var(--color--shadow);
  background-color: var(--color--background);
  background-color: var(--color--background);
  z-index: var(--z-index--over-toolbar);
  z-index: var(--z-index--over-toolbar);
  overflow-y: hidden;
  -webkit-transition: max-height 0.3s, right 0.3s;
  -o-transition: max-height 0.3s, right 0.3s;
  transition: max-height 0.3s, right 0.3s;
}

.expanded {
  right: 0;
}

.options {
  z-index: var(--z-index--over-toolbar);
  z-index: var(--z-index--over-toolbar);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
}

.option {
  height: var(--height--row);
  height: var(--height--row);
  background-color: var(--background-color);
  background-color: var(--background-color)
}

.option:hover {
    --background-color: var(--color--background-hover-highlight);
    --background-color: var(--color--background-hover-highlight);
  }

.arrow, .check {
  position: absolute;
  top: 50%;
  right: 6px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}
