.title, .value, .detail {
  padding: 16px
}

.title.truncate, .value.truncate, .detail.truncate {
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

.value, .detail {
  justify-self: end;
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.valueDetail {
  grid-column-end: span 2;
}
