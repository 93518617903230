.notEnoughData {
  position: absolute;
  font-variant-caps: all-small-caps;
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
  top: 0;
  bottom: 0;
  left: 20%;
  right: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px 6px;
  gap: 16px;
}

.any {
  color: var(--color--text-placeholder);
  color: var(--color--text-placeholder);
}
